<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Nombre *" />
      <BorderInput
        v-model="employee.data.name"
        label="Nombre *"
        @keyup.enter="onSave"
      />
      <FormError :show="employee.rules.name" message="Ingrese un nombre" />
      <BasicLabel label="CI *" />
      <BorderInput
        v-model="employee.data.identity"
        label="CI *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="employee.rules.identity"
        message="Ingrese un número de documento válido sin espacios, puntos y guiones"
      />
      <BasicLabel label="Correo *" />
      <BorderInput
        v-model="employee.data.email"
        label="Correo *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="employee.rules.email"
        message="Ingrese un email válido"
      />
      <BasicLabel label="Teléfono *" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="employee.data.phone"
          label="Teléfono *"
          @keyup.enter="onSave"
        />
      </div>
      <FormError
        :show="employee.rules.phone"
        message="Ingrese un teléfono válido"
      />
      <BasicLabel label="Perfil *" />
      <BorderSelect
        v-model="employee.data.companyRoleId"
        label="Perfil *"
        :options="store.state.general.roles"
      />
      <FormError
        :show="employee.rules.companyRoleId"
        message="Seleccione un perfil"
      />
      <BasicLabel label="Complejos *" />
      <SelectionTree
        :data="employee.tree"
        v-model:selectionKeys="employee.data.selected"
      />
      <FormError
        :show="employee.rules.selected"
        message="Seleccione como mínimo un Complejo"
      />
    </div>
    <div class="container-submit">
      <PrimaryButton label="Guardar" :click="onSave" />
    </div>
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import SelectionTree from "../../widgets/tree/SelectionTree";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, validation, actions } from "../../constants";

export default {
  components: {
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    SelectionTree,
    FormError,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const employee = reactive({
      tree: [],
      data: {
        id: "",
        name: "",
        identity: "",
        email: "",
        phone: "",
        companyRoleId: "",
        selected: {},
      },
      rules: {
        name: false,
        identity: false,
        email: false,
        phone: false,
        companyRoleId: false,
        selected: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!employee.data.name) {
        employee.rules.name = true;
        valid = false;
      }
      if (
        !employee.data.identity ||
        !validation.validateCI(employee.data.identity)
      ) {
        employee.rules.identity = true;
        valid = false;
      }
      if (
        !employee.data.email ||
        !validation.emailRegex.test(employee.data.email)
      ) {
        employee.rules.email = true;
        valid = false;
      }
      if (
        !employee.data.phone ||
        !validation.phoneRegex.test(employee.data.phone)
      ) {
        employee.rules.phone = true;
        valid = false;
      }
      if (!employee.data.companyRoleId) {
        employee.rules.companyRoleId = true;
        valid = false;
      }
      if (!Object.keys(employee.data.selected).length) {
        employee.rules.selected = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: employee.data.id,
        name: employee.data.name,
        identity: employee.data.identity,
        email: employee.data.email,
        phone: `+598 ${employee.data.phone}`,
        companyRoleId: employee.data.companyRoleId,
        buildings: Object.keys(employee.data.selected)
          .filter((key) => key !== "all")
          .map((key) => +key),
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(
          actions.employeeActions.update,
          request
        );
      } else {
        response = await store.dispatch(
          actions.employeeActions.create,
          request
        );
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedEmployees", []);
        router.push("/empleados/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const getSelectedKeys = (list) => {
      employee.tree[0].children.forEach((item) => {
        if (list.includes(item.key)) {
          employee.data.selected[item.key] = { checked: true };
        }
      });
      if (list.length === employee.tree[0].children.length) {
        employee.data.selected[employee.tree[0].key] = { checked: true };
      }
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/empleados/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      employee.tree.push({
        key: "all",
        label: "Todos",
        children: store.state.auth.user.buildings.map((item) => ({
          key: item.id,
          label: item.name,
        })),
      });
      await store.dispatch(
        actions.generalActions.roles,
        store.state.auth.user.company
      );
      store.commit("setLoading", false);
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.employee.selectedEmployees[0];
        employee.data.id = selected.id;
        employee.data.name = selected.name;
        employee.data.identity = selected.identity;
        employee.data.email = selected.email;
        employee.data.phone = selected.phone
          ? selected.phone.split(" ")[1]
          : "";
        employee.data.companyRoleId = selected.companyRoleId;
        getSelectedKeys(selected.buildings.map((item) => item.id));
      }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (employee.data.name) employee.rules.name = false;
      if (
        employee.data.identity &&
        validation.validateCI(employee.data.identity)
      )
        employee.rules.identity = false;
      if (
        employee.data.email &&
        validation.emailRegex.test(employee.data.email)
      )
        employee.rules.email = false;
      if (
        employee.data.phone &&
        validation.phoneRegex.test(employee.data.phone)
      )
        employee.rules.phone = false;
      if (employee.data.companyRoleId) employee.rules.companyRoleId = false;
      if (Object.keys(employee.data.selected).length)
        employee.rules.selected = false;
    });

    return { store, employee, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
